import React, {useContext} from 'react';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import omit from 'ramda/es/omit';
import {Col, Row, Button, Space, Input, Form} from 'antd';
import {LocaleContext} from '~src/context';
import {Control} from '~src/components';

const validateMessages = {
  required: 'Обязательное поле',
  types: {
    email: 'Некорректный email',
  },
};

interface IContentProps {}

const Content: React.FC<IContentProps> = () => {
  const {dispatch, app} = useStoreon<IState, IEvents>('app');

  const [userForm] = Form.useForm();
  const {
    pages: {
      admin: {
        account: {cancelButton, saveButton, ...accountValues},
      },
    },
  } = useContext(LocaleContext);

  const resetForm = () => {
    userForm.resetFields(['userName', 'userEmail', 'userPassword', 'userPasswordCheck']);
  };

  const handleSave = () => {
    userForm.validateFields().then(async values => {
      userForm.submit();
      dispatch('users/update-user', {...omit(['iat', 'userPasswordCheck'], values), userId: app.account.userId});
    });
  };
  if (!app.account.userEmail) return null;
  return (
    <Form
      form={userForm}
      initialValues={{userName: app.account.userName, userEmail: app.account.userEmail}}
      validateMessages={validateMessages}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={6}>
              <Control label={accountValues.userName.label}>
                <Form.Item name="userName" rules={[{required: true}]}>
                  <Input
                    placeholder={accountValues.userName.placeholder}
                    size={'large'}
                    name={'userName'}
                  />
                </Form.Item>
              </Control>
            </Col>
            <Col span={6}>
              <Control label={accountValues.userEmail.label}>
                <Form.Item name="userEmail" rules={[{required: true}]}>
                  <Input
                    placeholder={accountValues.userEmail.placeholder}
                    size={'large'}
                    name={'userEmail'}
                  />
                </Form.Item>
              </Control>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={6}>
              <Control label={accountValues.userPassword.label}>
                <Form.Item name="userPassword">
                  <Input
                    placeholder={accountValues.userPassword.placeholder}
                    size={'large'}
                    type={'password'}
                    name={'userPassword'}
                  />
                </Form.Item>
              </Control>
            </Col>
            <Col span={6}>
              <Control label={accountValues.userPasswordCheck.label}>
                <Form.Item
                  name="userPasswordCheck"
                  rules={[
                    ({getFieldValue}) => ({
                      validator(rule, value) {
                        if (getFieldValue('userPassword') !== value) {
                          return Promise.reject('Пароли не совпадают');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder={accountValues.userPasswordCheck.placeholder}
                    size={'large'}
                    type={'password'}
                    name={'userPasswordCheck'}
                  />
                </Form.Item>
              </Control>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Space size={20}>
            <Button onClick={resetForm} danger>
              {cancelButton}
            </Button>
            <Button onClick={handleSave}>{saveButton}</Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default Content;
