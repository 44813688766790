import React, {useContext} from 'react';
import {LocaleContext} from '~src/context';
import locales from '~src/locales';

import GlobalStyle from '~src/theme/global-style';
import {ErrorBoundary, AdminTemplate} from '~src/components';
import Providers from '~src/components/Providers';
import Content from '~src/components/organisms/AdminOrganism/Account/Content';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

interface IAccountPageProps {}

const AccountPage: React.FC<IAccountPageProps> = () => {
  const {dispatch} = useStoreon<IState, IEvents>('auth');
  const {
    pages: {admin},
  } = useContext(LocaleContext);
  const handleClickAtButton = () => {
    dispatch('auth/sing-out');
  };
  return (
    <Providers>
      <ErrorBoundary>
        <LocaleContext.Provider value={locales}>
          <GlobalStyle />
          <AdminTemplate
            pageTitle={admin.account.title}
            onClickAtButton={handleClickAtButton}
            buttons={admin.account.pageControls.buttons}
          >
            <Content />
          </AdminTemplate>
        </LocaleContext.Provider>
      </ErrorBoundary>
    </Providers>
  );
};

export default AccountPage;
